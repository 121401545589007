/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './src/styles/globals.css';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITEKEY || ''}
      scriptProps={{
        async: true, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
      }}>
      {element}
    </GoogleReCaptchaProvider>
  );
};
